import React, { Component } from 'react'

class Logo extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="172" height="20" viewBox="0 0 172 20" fill="none">
        <g className="logo" style={{ mixBlendMode: 'multiply' }}>
        <path d="M0 11.957L2.62817 11.5523C2.95308 14.5604 4.73046 15.9763 7.45851 15.9763C9.91098 15.9763 11.7136 14.7634 11.7136 12.8418C11.7136 11.2242 10.6872 10.6177 8.35984 10.1121L5.88209 9.55542C2.8532 8.89789 0.651025 7.73476 0.651025 4.90411C0.651025 2.07346 3.27919 0 7.10833 0C10.9375 0 13.3659 1.92154 13.7413 5.13139L11.1132 5.53612C10.8629 3.31195 9.41159 2.02241 7.03372 2.02241C4.65585 2.02241 3.22985 3.2609 3.22985 4.77771C3.22985 6.29452 4.35621 6.82565 6.50905 7.30573L8.98679 7.83686C12.1902 8.54421 14.3177 9.63199 14.3177 12.5635C14.3177 16.0772 10.9892 17.9988 7.40917 17.9988C3.58004 17.9988 0.451265 16.2291 0 11.957Z" fill={color}/>
        <path d="M17.846 16.0019C17.7209 15.5971 17.6956 15.117 17.6956 14.5859V7.22914H15.4682V5.20673H17.6956V1.26399H20.124V5.20794H22.6523V7.23036H20.124V14.3343C20.124 15.3966 20.6752 15.7503 21.6511 15.7503C22.1012 15.7503 22.3767 15.7247 22.7775 15.6749V17.6463C22.1264 17.7727 21.6258 17.8237 20.9255 17.8237C19.3491 17.8237 18.2468 17.2671 17.8472 16.0031L17.846 16.0019Z" fill={color}/>
        <path d="M24.8052 5.20799H27.2576V12.3873C27.2576 14.586 27.7077 15.901 29.7354 15.901C31.5874 15.901 32.8642 14.2323 32.8642 12.2609V5.20799H35.2926V17.6962H32.8894V15.9776H32.8642C32.1133 17.0398 30.8112 18 29.0603 18C26.4574 18 24.8052 16.3057 24.8052 13.3232V5.20799Z" fill={color}/>
        <path d="M37.295 11.4514C37.295 7.58407 39.5224 4.90412 42.851 4.90412C44.9785 4.90412 46.2806 6.06726 46.9051 7.05294V0.30386H49.3335V17.6961H46.9051V15.8001C46.3046 16.8113 45.0279 18 42.851 18C39.5224 18 37.295 15.32 37.295 11.4527V11.4514ZM46.9809 11.4514C46.9809 8.8736 45.6548 6.92654 43.3516 6.92654C41.0483 6.92654 39.8221 8.8736 39.8221 11.4514C39.8221 14.0293 40.999 15.9764 43.3516 15.9764C45.7042 15.9764 46.9809 14.0305 46.9809 11.4514Z" fill={color}/>
        <path d="M51.8871 1.76966C51.8871 0.884853 52.5128 0.252848 53.4394 0.252848C54.366 0.252848 54.9665 0.884853 54.9665 1.76966C54.9665 2.65447 54.3408 3.33752 53.4394 3.33752C52.5381 3.33752 51.8871 2.67999 51.8871 1.76966ZM52.212 17.6962V5.20801H54.6645V17.6962H52.212Z" fill={color}/>
        <path d="M56.9148 11.4514C56.9148 7.78578 59.218 4.90408 62.9713 4.90408C66.7247 4.90408 69.0039 7.78578 69.0039 11.4514C69.0039 15.117 66.7006 17.9987 62.9713 17.9987C59.2421 17.9987 56.9148 15.1426 56.9148 11.4514ZM66.4756 11.4514C66.4756 8.99996 65.3745 6.9265 62.9713 6.9265C60.5682 6.9265 59.4418 8.97444 59.4418 11.4514C59.4418 13.9284 60.5429 15.9763 62.9713 15.9763C65.3997 15.9763 66.4756 13.9284 66.4756 11.4514Z" fill={color}/>
        <path d="M84.8234 0.30386H89.028V17.6961H86.5502V6.47198C86.5502 4.87982 86.6248 3.2366 86.6501 2.4782L86.5996 2.45268L81.6188 17.6961H78.7151L73.7596 2.45268L73.709 2.4782C73.7343 3.2366 73.8089 4.87982 73.8089 6.47198V17.6961H71.3059V0.30386H75.5105L80.1663 15.0162L84.8234 0.30386Z" fill={color}/>
        <path d="M91.3313 14.3076C91.3313 12.0068 92.9077 10.9701 95.5611 10.4147L97.3132 10.061C98.5647 9.78267 99.1905 9.50435 99.1905 8.46883C99.1905 7.43332 98.365 6.77458 96.8872 6.77458C95.235 6.77458 94.1592 7.81131 94.0593 9.42899L91.5563 9.20172C91.732 6.57282 93.809 4.90408 96.8379 4.90408C99.8668 4.90408 101.668 6.36985 101.668 9.12636V17.6961H99.3157V16.3057H99.2904C98.7152 17.216 97.4131 17.9999 95.5864 17.9999C93.3842 17.9999 91.3313 16.6606 91.3313 14.3088V14.3076ZM99.2904 13.0946V11.4514C97.0377 11.8306 95.7862 12.1588 95.0352 12.589C94.3096 12.9682 93.8584 13.4738 93.8584 14.2578C93.8584 15.4209 94.8343 16.1793 96.2109 16.1793C97.9631 16.1793 99.2892 15.0417 99.2892 13.0946H99.2904Z" fill={color}/>
        <path d="M103.834 11.4514C103.834 7.5585 106.287 4.90408 109.867 4.90408C112.996 4.90408 114.822 6.77458 115.473 9.17619L112.945 9.8082C112.644 8.19051 111.643 6.9265 109.842 6.9265C107.814 6.9265 106.363 8.56971 106.363 11.4259C106.363 14.2821 107.814 15.9763 109.892 15.9763C111.594 15.9763 112.671 14.9141 113.046 13.0946L115.549 13.7266C114.824 16.0772 113.071 17.9987 109.892 17.9987C106.263 17.9987 103.836 15.3443 103.836 11.4514H103.834Z" fill={color}/>
        <path d="M117.675 17.6949V0.30386H124.483C127.787 0.30386 129.939 1.79515 129.939 4.77772C129.939 6.74909 128.662 8.06415 127.512 8.5187V8.54423C128.738 8.84807 130.465 10.061 130.465 12.6644C130.465 15.6227 128.463 17.6949 124.733 17.6949H117.675ZM120.228 7.60837H124.383C126.26 7.60837 127.387 6.77461 127.387 4.92843C127.387 3.08225 126.16 2.50129 124.483 2.50129H120.228V7.60837ZM124.683 15.4951C126.86 15.4951 127.911 14.4085 127.911 12.5623C127.911 10.7161 126.81 9.73167 124.607 9.73167H120.227V15.4951H124.683Z" fill={color}/>
        <path d="M139.452 4.95513V7.3057C139.227 7.28018 139.051 7.28018 138.751 7.28018C136.599 7.28018 135.222 8.54419 135.222 10.9458V17.6949H132.769V5.20793H135.173V7.45763C135.648 6.14257 136.874 4.90408 138.777 4.90408C139.052 4.90408 139.252 4.92961 139.453 4.95513H139.452Z" fill={color}/>
        <path d="M140.788 1.76966C140.788 0.884853 141.413 0.252848 142.34 0.252848C143.266 0.252848 143.867 0.884853 143.867 1.76966C143.867 2.65447 143.241 3.33752 142.34 3.33752C141.439 3.33752 140.788 2.67999 140.788 1.76966ZM141.112 17.6962V5.20801H143.565V17.6962H141.112Z" fill={color}/>
        <path d="M145.743 11.4514C145.743 7.58407 147.97 4.90412 151.299 4.90412C153.427 4.90412 154.729 6.06726 155.353 7.05294V0.30386H157.782V17.6961H155.353V15.8001C154.753 16.8113 153.476 18 151.299 18C147.97 18 145.743 15.32 145.743 11.4527V11.4514ZM155.429 11.4514C155.429 8.8736 154.103 6.92654 151.8 6.92654C149.496 6.92654 148.27 8.8736 148.27 11.4514C148.27 14.0293 149.447 15.9764 151.8 15.9764C154.152 15.9764 155.429 14.0305 155.429 11.4514Z" fill={color}/>
        <path d="M171.323 12.0081H162.187C162.287 14.637 163.739 16.0018 165.641 16.0018C167.342 16.0018 168.394 15.066 168.77 13.5504L171.247 13.8785C170.672 15.9265 169.02 17.9987 165.615 17.9987C162.211 17.9987 159.784 15.6226 159.784 11.4769C159.784 7.33123 162.387 4.90408 165.691 4.90408C168.996 4.90408 171.323 7.48315 171.323 11.1986V12.0081ZM168.771 10.1376C168.671 8.39348 167.494 6.87667 165.693 6.87667C163.79 6.87667 162.439 8.24156 162.239 10.1376H168.772H168.771Z" fill={color}/>
        </g>
      </svg>      
    )
  }
}

// class LogoMark extends Component {
//   render() {
//     let { color } = this.props
//     return (
//      <>

//      </>
//     )
//   }
// }

class Arrow extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none">
        <path d="M1 1L5 5L1 9" stroke={color} stroke-width="0.8"/>
      </svg>
    )
  }
}



class Close extends Component {
  render() {
    let { color } = this.props
    return (
<svg width="8" height="8" viewBox="0 0 8 8" fill="none">
<path d="M7.40457 1.17679L1.04061 7.54075M7.68742 7.54075L1.32346 1.17679" stroke={color} stroke-width="0.8"/>
</svg>

    )
  }
}

export { Logo, Close, Arrow }