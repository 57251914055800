import '../assets/scss/main.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Fade } from 'react-reveal';

import layoutQuery from "../hooks/use-layout-query";
import Header from '../components/header';
import Footer from '../components/footer';
import Landing from '../components/landing';

const Layout = ({ children, location, pageContext }) => {
  const data = layoutQuery();
  const [isNavigating, setIsNavigating] = useState(false);
  const [lastClickedLink, setLastClickedLink] = useState(null);
  const [key, setKey] = useState(0);

  const handleLinkClick = (path) => {
    if (path !== lastClickedLink) {
      setIsNavigating(true); // Set navigating to true to trigger fade out
      setLastClickedLink(path);
      setTimeout(() => {
        navigate(path);
      }, 500); // Adjust timing for slower fade out
    }
  };

  useEffect(() => {
    if (isNavigating) {
      setTimeout(() => {
        setIsNavigating(false); // Reset navigating state after fade out
        setKey((prevKey) => prevKey + 1); // Increment key to trigger re-render and immediate appearance
      }, 500); // Adjust timing for slower fade out
    }
  }, [isNavigating]);

  // Determine the current page
  const currentPage = location.pathname;

  return (
    <>
      <Header location={location} data={data} pageContext={pageContext} handleLinkClick={handleLinkClick} />
      
      {currentPage === '/' && (
        <Landing data={data} />
      )}

      <Fade duration={500} opposite when={!isNavigating}>
        <main key={key}>
          {React.Children.map(children, child =>
            React.cloneElement(child, { handleLinkClick })
          )}
        </main>
      </Fade>
      
      <Footer data={data} currentPage={currentPage} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
