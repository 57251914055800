import React, { Component } from 'react';
import Link from '../utils/link'

class Footer extends Component {
  render() {
    const currentPage = this.props.currentPage; // Assuming currentPage is passed as a prop

    return (
      <footer className='footer'>
        <div className='footer__inner'>
          
        {currentPage === '/contact/' || currentPage === '/studio/' ? (
          <>
  <p>&#169; StudioMacBride 2024</p>
  </>
) : (
  <h2>Interior Architecture & Design</h2>
)}
          <h3>London, Berlin, Athens</h3>
        </div>
      </footer>
    );
  }
}

export default Footer;