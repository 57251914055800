import React, { useRef, useEffect, useState } from "react";
import Lottie from "lottie-react";
import animationData from "../assets/animation.json";
import { useInView } from "react-intersection-observer";

const LottieAnimation = () => {
  const { ref } = useInView();
  const animationRef = useRef();
  const [isReversed, setIsReversed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      if (currentScrollPosition > 100 && !isReversed) {
        setIsReversed(true);
        animationRef.current?.setDirection(1); // Forward playback
        animationRef.current?.play();
      } else if (currentScrollPosition <= 100 && isReversed) {
        setIsReversed(false);
        animationRef.current?.setDirection(-1); // Reverse playback
        animationRef.current?.play();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isReversed]);

  return (
    <div className="logo-animation" ref={ref}>
      <Lottie
        animationData={animationData}
        autoplay={false}
        loop={false}
        lottieRef={animationRef}
      />
    </div>
  );
};

export default LottieAnimation;
