import React, { useEffect, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import { Logo } from '../components/icons';

const useSessionModal = () => {
  const session = "landing";
  const [show, setShow] = useState(true);

  const toggleShow = () => {
    const modalKey = "modalSession";
    sessionStorage.setItem(modalKey, session);
    setShow(!show);
  };

  useEffect(() => {
    const modalKey = "modalSession";
    const modalSession = sessionStorage.getItem(modalKey);
    setShow(modalSession !== session);
  }, []);

  return [show, toggleShow];
};

const Landing = ({ data }) => {
  const [show, toggleShow] = useSessionModal();
  const [fadeOut, setFadeOut] = useState(false);
  const [fadeIn, setFadeIn] = useState(false); // New state for fading in text

  const handleFadeOut = () => {
    setFadeOut(true);
    setTimeout(() => toggleShow(), 500); // Adjust the duration to match your CSS transition duration
  };

  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        handleFadeOut();
      }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, []);

  useEffect(() => {
    if (show) {
      console.log("Adding class to body");
//      document.body.classList.add('landing-active');
      document.body.classList.add('landing-active');
      document.documentElement.classList.add('landing-active');

      document.body.style.overflow = 'hidden';
      // Triggering the fade-in effect with a delay to allow the fade-out transition to complete
      setTimeout(() => {
        // document.body.classList.add('landing-active');
        setFadeIn(true);
      }, 1500);
    } else {
      console.log("Removing class from body");
      document.documentElement.classList.remove('landing-active');

      // document.body.classList.add('landing-not-active');
      document.body.style.overflow = 'auto';
    }
  }, [show]);
  
  return (
    show && ( // Only render if 'show' is true
      <section id="landing" className={fadeOut ? 'fade-out' : ''}>
        <div onClick={handleFadeOut} className="landing__inner">

          <div className="landing__logo">
            <div className="desktop">
              <Logo color="#282727" />
            </div>

            <div className={`landing__cta ${fadeIn ? 'fade-in' : ''}`}> {/* Applying fade-in class */}
              {data.wp.siteSettings.acf.landingText &&
                <h1>{parse(data.wp.siteSettings.acf.landingText)}</h1>
              }
            </div>
          </div>
        </div>
      </section>
    )
  );
};

export default Landing;
