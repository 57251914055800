import React, { useState, useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import LottieAnimation from "../components/lottie-animation";
import { Logo } from '../components/icons';

const Header = ({ handleLinkClick }) => {
  const [offCanvas, setOffCanvas] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [showProjectsMenu, setShowProjectsMenu] = useState(false);
  const [showStoriesMenu, setShowStoriesMenu] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [menusContainerActive, setMenusContainerActive] = useState(false);

  const hideOffCanvas = () => {
    setOffCanvas(false);
  };

  const toggleOffCanvas = () => {
    setOffCanvas(prevState => !prevState);
  };

  const closeProjectsMenu = () => {
    setShowProjectsMenu(false);
    setMenusContainerActive(false); // Close menus container when projects menu is closed
  };

  const closeStoriesMenu = () => {
    setShowStoriesMenu(false);
    setMenusContainerActive(false); // Close menus container when stories menu is closed
  };

  const handleHover = (image) => {
    setHoveredImage(image);
  };
  
  const handleLinkClickHandler = (link) => {
    setActiveLink(link);
    handleLinkClick(link);
    hideOffCanvas();
    setShowProjectsMenu(false); 
    setShowStoriesMenu(false); 
  };

  const toggleProjectsMenu = () => {
    setShowProjectsMenu(prevState => !prevState);
    setShowStoriesMenu(false);
    setActiveLink(prevState => prevState === 'projects' ? null : 'projects');
    setMenusContainerActive(!showProjectsMenu); // Toggle menus container active
  };

  const toggleStoriesMenu = () => {
    setShowStoriesMenu(prevState => !prevState);
    setShowProjectsMenu(false);
    setActiveLink(prevState => prevState === 'stories' ? null : 'stories');
    setMenusContainerActive(!showStoriesMenu); // Toggle menus container active
  };

  const isNavActive = activeLink || showProjectsMenu || showStoriesMenu;


  const props = {
    onClick: () => {
      hideOffCanvas();
      closeProjectsMenu();
      closeStoriesMenu(); // Close both menus if header is clicked
    },
    activeClassName: 'active'
  };


  const data = useStaticQuery(graphql`
    query {
      allWpProject(sort: { fields: date, order: DESC }) {
        edges {
          node {
            title
            uri
            slug
            id
            acf {
              location
              featureImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1200)
                  }
                }
              }
            }
          }
        }
      }
      allWpStory(sort: { fields: title, order: ASC }) {
        edges {
          node {
            title
            uri
            slug
            id
            acf {
              location
              featureImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1200)
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

    // Clear hoveredImage when navigating away from the section
    useEffect(() => {
      setHoveredImage(null);
    }, [activeLink]);

  return (
    <>
      <header className='header'>
        <div className='header__inner'>
          <div className='header__col'>
            <Link to='/' title='Studio MacBride' className='header__logo' onClick={() => handleLinkClickHandler('/')}>
              {/* <Logo color='#282727' /> */}

              <LottieAnimation />
            </Link>
          </div>
          <div className='header__col'>
            <nav className={`header__nav ${isNavActive ? 'active' : ''}`}>
              <ul>
                <li>
                  <button onClick={toggleProjectsMenu} className={activeLink === 'projects' || showProjectsMenu ? 'active' : ''}>
                    Projects
                  </button>
                </li>
                <li>
                  <button onClick={toggleStoriesMenu} className={activeLink === 'stories' || showStoriesMenu ? 'active' : ''}>
                    Stories
                  </button>
                </li>
                <li>
                  <Link to='/studio' onClick={() => handleLinkClickHandler('/studio')} className={activeLink === '/studio' ? 'active' : ''}>
                    Studio
                  </Link>
                </li>
                <li>
                  <Link to='/contact' onClick={() => handleLinkClickHandler('/contact')} className={activeLink === '/contact' ? 'active' : ''}>Contact</Link>
                </li>
              </ul>
            </nav>
            <button onClick={toggleOffCanvas} className={offCanvas ? 'header__hamburger active' : 'header__hamburger'}>
              <span className='lines'></span>
            </button>
          </div>
        </div>
      </header>

      <div className={`menus-container ${menusContainerActive ? 'active' : ''}`} onClick={() => {
        closeProjectsMenu();
        closeStoriesMenu();
      }}>
        {showProjectsMenu && (
          <div className={`projects-menu ${showProjectsMenu ? 'projects-menu--visible' : ''}`}>
            <div className='projects-menu__inner'>
              <div className='projects-menu__col'>
                {hoveredImage && (
                  <div className="projects-menu__project-image-overlay">
                    <GatsbyImage image={getImage(hoveredImage.localFile)} alt={hoveredImage.altText} />
                  </div>
                )}
              </div>
              <div className='projects-menu__col'>
                <ul className='subnav'>
                  {data.allWpProject.edges.map(({ node }) => (
                    <li key={node.id} onMouseEnter={() => handleHover(node.acf.featureImage)}>
                      <Link to={node.uri} onClick={() => handleLinkClick()}>
                        <span className='project'>{node.title}</span>
                        <span className='spacer'></span>
                        <span className='location'>{node.acf.location}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='projects-menu__footer'>
                <div className='projects-menu__footer--inner'>
                    <h2>Interior Architecture & Design</h2>
                    <h3>London, Berlin, Athens</h3>
                  </div>
                </div>
          </div>
        )}

        {showStoriesMenu && (
          <div className={`stories-menu ${showStoriesMenu ? 'stories-menu--visible' : ''}`}>
            <div className='stories-menu__inner'>
              <div className='stories-menu__col'>
                {hoveredImage && (
                  <div className="stories-menu__story-image-overlay">
                    <GatsbyImage image={getImage(hoveredImage.localFile)} alt={hoveredImage.altText} />
                  </div>
                )}
              </div>
              <div className='stories-menu__col'>
                <ul className='subnav'>
                  {data.allWpStory.edges.map(({ node }) => (
                    <li key={node.id} onMouseEnter={() => handleHover(node.acf.featureImage)}>
                      <Link to={node.uri} onClick={() => handleLinkClick()}>
                        <span className='project'>{node.title}</span>
                        <span className='spacer'></span>
                        <span className='location'>{node.acf.location}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='projects-menu__footer'>
                <div className='projects-menu__footer--inner'>
                    <h2>Interior Architecture & Design</h2>
                    <h3>London, Berlin, Athens</h3>
                  </div>
                </div>
          </div>
        )}
      </div>

      <div className={`off-canvas ${offCanvas && 'off-canvas--active'}`} style={{ backgroundColor: showProjectsMenu ? '#BFA487' : (showStoriesMenu ? '#AA9D92' : '#EEE9E2') }}>
        <div className='off-canvas__inner'>
          <nav className='off-canvas__nav'>
            <ul>
              <li>
                <button onClick={toggleProjectsMenu}>
                  Projects
                </button>
                <ul className={`submenu ${showProjectsMenu ? 'active' : ''}`}>
                  {data.allWpProject.edges.map(({ node }) => (
                    <li key={node.id} onMouseEnter={() => handleHover(node.acf.featureImage)}>
                      <Link to={node.uri} onClick={() => handleLinkClick()}   {...props}>
                        <span className='subnav-container'>
                          <span className='project'>{node.title}</span>
                          <span className='location'>{node.acf.location}</span>
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <button onClick={toggleStoriesMenu}>
                  Stories
                </button>
                <ul className={`submenu ${showStoriesMenu ? 'active' : ''}`}>
                  {data.allWpStory.edges.map(({ node }) => (
                    <li key={node.id} onMouseEnter={() => handleHover(node.acf.featureImage)}>
                      <Link to={node.uri} onClick={() => handleLinkClick()}   {...props}>
                        <span className='subnav-container'>
                          <span className='project'>{node.title}</span>
                          <span className='location'>{node.acf.location}</span>
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <Link to='/studio' onClick={() => handleLinkClick()}   {...props}>
                  Studio
                </Link>
              </li>
              <li>
                <Link to='/contact' onClick={() => handleLinkClick()}   {...props}>
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
